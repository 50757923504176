import React, { useState, useEffect } from "react";

import { Spin } from "antd";

import { fetchData, URL_STATION } from "../../../utils/api";

import GCOSLinkTimeSeries from "../LinkTimeSeries/LinkTimeSeries";
import LinkOSCAR from "../../Map/LinkOSCAR/LinkOSCAR";

import "./Popup.css";

const GUANVariableUnits = {
  humidity: "%",
  wind: "m/s",
  temperature: "K"
};

const GCOSPopup = props => {
  const [content, setContent] = useState(<Spin tip="Loading..." />);

  const dataParams = {
    station: props.stationObject.station_id,
    date: props.selectedDate
  };

  const linkTimeSeries = (
    <GCOSLinkTimeSeries
      fileType={props.fileType}
      data={dataParams}
      onSetChartData={props.onSetChartData}
      setVisible={props.setVisible}
    />
  );

  useEffect(() => {
    fetchData(
      `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
    ).then(response => {
      const metadata = response.data;

      // Check if the station we clicked on is
      // in OSCAR or not (hence, if we have to
      // display the link to OSCAR)
      let linkOSCAR;
      let labelIDStation = "Reported ID";

      if (metadata.in_oscar) {
        linkOSCAR = (
          <>
            <hr />
            <LinkOSCAR wigosid={metadata.wigosid} />
          </>
        );
        labelIDStation = "WIGOS-ID";
      }

      const metadataPart = (
        <ul className="popup-station-properties">
          <li key={1}>Name: {metadata.name}</li>
          <li key={2}>
            {labelIDStation}: {metadata.wigosid}
          </li>
        </ul>
      );

      const links = (
        <>
          {linkTimeSeries}
          <br />
          {linkOSCAR}
        </>
      );

      const fieldValues = Object.entries(props.stationObject).map((e, i) => {
        let ret;
        if (
          e[0] !== "station_id" &&
          e[0] !== "in_oscar" &&
          e[0] !== "country_id"
        ) {
          let mapValue = e[1].toString();
          let varNameToDisplay = e[0];
          if (
            props.fileType === "gsn" &&
            props.selectedReport === "completeness" &&
            varNameToDisplay.startsWith("days_with_")
          ) {
            varNameToDisplay = "#Days";
            if (e[1] === -1) {
              mapValue = "Not provided";
            }
          }

          if (
            props.fileType === "gsn" &&
            props.selectedReport === "availability"
          ) {
            if (e[0] === "availability") {
              if (e[1]) {
                mapValue = "Reporting";
              } else {
                mapValue = "Not reporting";
              }
            }
            if (e[0] === "country_id") {
              mapValue = e[1];
            }
          }

          // for GUAN/Quality, also show the unit
          if (props.fileType === "guan" && props.selectedReport === "quality") {
            mapValue += GUANVariableUnits[e[0]];
          }

          /* For GUAN/quality, display the active variable as bold */
          ret = (
            <li
              key={i}
              className={
                props.fileType === "guan" &&
                props.selectedReport === "quality" &&
                varNameToDisplay === props.selectedVariable
                  ? "guan-quality-active-variable"
                  : null
              }
            >
              {varNameToDisplay.charAt(0).toUpperCase() +
                varNameToDisplay.slice(1)}
              :
              {/* For GUAN/availability, if the value = -1, display "Missing data" */}
              {props.fileType === "guan" && mapValue === "-1"
                ? "Missing data"
                : mapValue}
            </li>
          );
        }
        return ret;
      });
      const valuesPart = (
        <ul className="popup-station-properties">{fieldValues}</ul>
      );

      // Used to fill in the title of the chart component
      props.onSetSelectedStation({
        name: metadata.name,
        wigosid: metadata.wigosid,
        in_oscar: metadata.in_oscar
      });

      const newContent = (
        <>
          {metadataPart}
          {valuesPart}
          {links}
        </>
      );
      setContent(newContent);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        onClick={props.obj.remove}
        className="mapboxgl-popup-close-button"
        type="button"
        aria-label="Close popup"
      >
        ×
      </button>
      <div id="popup-content">{content}</div>
    </>
  );
};

export default GCOSPopup;
